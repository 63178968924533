<template>
	<div class="article">
		<h2>{{ this.articleDetail.title }}</h2>
		<div class="article_time">{{ this.articleDetail.created_at }}</div>
		<div v-html="articleDetail.content"></div>
	</div>
</template>
<style lang="less" scoped>
.article {
	width: 1200px;
	margin: 0 auto;
	padding: 20px 0 100px;

	h2 {
		text-align: left;
		color: #000;
	}
	.article_time {
		text-align: left;
		color: #999;
	}
}
</style>
<script>
import * as Api from '@/api/index/index';
import { formatDate, formatMoment } from '@/utils/utils.js';
export default {
	name: 'articleDetail',
	data() {
		return {
			articleDetail: [],
		};
	},
	mounted() {
		this.getArticleDetail();
	},
	methods: {
		getArticleDetail() {
			Api.articleParameter(
				'',
				(res) => {
					res.created_at = formatMoment(res.created_at);
					this.articleDetail = res;
				},
				this.$route.params.id
			);
		},
	},
};
</script>
